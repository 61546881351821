import qs from 'qs';

import { API_URL } from './settings';

const getOptions = () => ({
	headers: new Headers({
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	})
});

export const search = async (terms) => {
	const response = await fetch(`${API_URL}/search/live?q=${terms}`, getOptions());
	const { results } = await response.json();
	return results;
};

export const fetchDashboardStats = async () => {
	const response = await fetch(`${API_URL}/dashboard/stats`, getOptions());
	return await response.json();
};

export const fetchUserStats = async (id) => {
	const response = await fetch(`${API_URL}/stats/users/${id}`, getOptions());
	return await response.json();
};

export const fetchUsersLeavesStats = async (data) => {
	const query = data ? `?${qs.stringify(data, { arrayFormat: 'comma', encode: false, skipNulls: true })}` : '';
	const response = await fetch(`${API_URL}/stats/users/leaves${query}`, getOptions());
	return await response.json();
};

export const fetchUserLeavesStats = async (id, data) => {
	const query = data ? `?${qs.stringify(data, { arrayFormat: 'comma', encode: false, skipNulls: true })}` : '';
	const response = await fetch(`${API_URL}/stats/users/${id}/leaves${query}`, getOptions());
	return await response.json();
};

/* Account */
export const getAccount = async () => {
	const response = await fetch(`${API_URL}/account`, getOptions());
	return await response.json();
};

export const updateAccount = async (data) => {
	const response = await fetch(`${API_URL}/account`, { ...getOptions(), method: 'PUT', body: data });
	return await response.json();
};

export const forgotPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/forget_password`, { method: 'POST', body: data });
	return await response.json();
};

export const resetPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/reset_password`, { method: 'POST', body: data });
	return await response.json();
};

/* Leave */
export const markAcceptedLeave = async (id, data) => {
	const response = await fetch(`${API_URL}/leaves/${id}/mark_accepted`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

export const markRefusedLeave = async (id, data) => {
	const response = await fetch(`${API_URL}/leaves/${id}/mark_refused`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

/* Time entries */
export const fetchTimeEntriesStats = async (data) => {
	const query = data ? `?${qs.stringify(data, { arrayFormat: 'comma', encode: false, skipNulls: true })}` : '';
	const response = await fetch(`${API_URL}/stats/time_entries${query}`, getOptions());
	return await response.json();
};

export const fetchTimeEntryTypes = async (data) => {
	const query = data ? `?${qs.stringify(data, { arrayFormat: 'comma', encode: false, skipNulls: true })}` : '';
	const response = await fetch(`${API_URL}/time_entry_types${query}`, getOptions());
	return await response.json();
};

/* Projects */
export const fetchProjects = async (data) => {
	const query = data ? `?${qs.stringify(data, { arrayFormat: 'comma', encode: false, skipNulls: true })}` : '';
	const response = await fetch(`${API_URL}/projects${query}`, getOptions());
	return await response.json();
};

/* Suggestions */
export const addSuggestionComment = async (id, data) => {
	const response = await fetch(`${API_URL}/suggestions/${id}/comments`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

export const updateSuggestionComment = async (id, data) => {
	const response = await fetch(`${API_URL}/suggestions/comments/${id}`, { ...getOptions(), method: 'PUT', body: data });
	return await response.json();
};

export const downvoteSuggestion = async (id, data) => {
	const response = await fetch(`${API_URL}/suggestions/${id}/downvote`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};

export const upvoteSuggestion = async (id, data) => {
	const response = await fetch(`${API_URL}/suggestions/${id}/upvote`, { ...getOptions(), method: 'POST', body: data });
	return await response.json();
};