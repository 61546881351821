import React from 'react';
import { SimpleShowLayout, TextField, ReferenceField, TextInput, SimpleForm, RecordContextProvider, usePermissions, useRecordContext, useTranslate, useNotify, useRefresh, useGetIdentity } from 'react-admin';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useWatch, useFormContext } from 'react-hook-form';

import { ShowBase, ShowActions } from '../common/views/Show';
import DateField from '../common/fields/DateField';
import MultilineTextField from '../common/fields/MultilineTextField';
import RichTextField from '../common/fields/RichTextField';
import CategoryField from './fields/CategoryField';
import StatusField from './fields/StatusField';
import VoteField from './fields/VoteField';
import { HumanDateField } from 'components/common';

import { addSuggestionComment } from '../../services/api';


const SuggestionShowActions = (props) => {
	const record = useRecordContext();
	const { data: account } = useGetIdentity();
	const { permissions } = usePermissions();

	return (
		<ShowActions
			{...props}
			hasEdit={permissions?.includes('is_admin') || (record?.status === 'new' && record?.user_id === account?.id)}
		/>
	);
};

const ButtonForm = () => {
	const record = useRecordContext();
	const { setValue } = useFormContext();
	const content = useWatch({ name: 'content' });
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();

	const handleAddComment = async () => {
		const formData = new FormData();
		formData.append('content', content);
		const response = await addSuggestionComment(record.id, JSON.stringify({ content }));

		if (response.id) {
			setValue('content', '');
			notify(`Comment added`);
			refresh();
		} else {
			notify('Comment could not be added', 'warning');
		}
	};

	return (
		<Button variant="contained" onClick={handleAddComment} sx={{ marginTop: 1 }}>{translate('Add')}</Button>
	);
};

const CommentForm = () => (
	<SimpleForm toolbar={false} sx={{ padding: 0 }}>
		<TextInput source="content" label="Comment" fullWidth multiline rows="4" helperText={false} />
		<ButtonForm />
	</SimpleForm>
);

const CommentsField = () => {
	const record = useRecordContext();

	return (
		record?.comments?.map((comment, index) => (
			<RecordContextProvider value={comment} key={comment.id}>
				<Box display="flex" flexDirection="column" pb={3}>
					<Box display="flex" alignItems="center" gap={1} mb={1}>
						<Avatar sx={{ bgcolor: comment?.user?.color, fontSize: '14px' }}>{comment?.user?.trigram}</Avatar>
						<TextField source="user.fullname" sx={{ fontWeight: 'bold' }} record={comment} />
						<HumanDateField source="created_at" record={comment} sx={{ color: 'gray' }} />
					</Box>
					<MultilineTextField source="content" record={comment} sx={{ marginBottom: 3 }} />

					{index < record.comments?.length - 1 && (
						<Divider />
					)}
				</Box>
			</RecordContextProvider>
		))
	);
};

const CustomDivider = () => <Divider width="100%" />;

const SuggestionShow = () => {
	const translate = useTranslate();

	return (
		<ShowBase>
			<SuggestionShowActions />
			<Grid container spacing={2} sx={{ marginBottom: '30px' }}>
				<Grid item xs={12}>
					<Paper>
						<SimpleShowLayout>
							<TextField source="title" />
							<CategoryField source="category" />
							<StatusField source="status" />
							<ReferenceField label="User" source="user_id" reference="users">
								<TextField source="fullname" />
							</ReferenceField>
							<DateField source="created_at" />

							<CustomDivider label={false} />

							<RichTextField source="description" label={false} />
						</SimpleShowLayout>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Card>
						<CardHeader title={translate('Votes')} />
						<CardContent>
							<Box display="flex" gap={5}>
								<VoteField size={32} />
							</Box>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card>
						<CardHeader title={translate('Comments')} />
						<CardContent>
							<CommentsField source="comments" />
							<CommentForm />
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</ShowBase>
	);
};

export default SuggestionShow;